body {
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  text-align: left;
  text-transform: uppercase;
  font-size: 1.2rem;
  letter-spacing: 0.3rem;
  margin-top: 0;
}

section {
  padding: 2rem;
}